import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/organisms/hero"
import Sponsors from "../components/organisms/sponsors"

import "../components/pages/privacy-policy.scss"

const privacyPolicy = () => (
  <Layout>
    <SEO
      title="Privacy Policy"
      keywords={[`Privacy`, `Fiftyone3`, `Cardiff`]}
    />
    <Hero heading="Privacy Policy" lede=" " style={{ zIndex: "1" }} event={null} />
    <div className="index" style={{ zIndex: "2", position: "relative" }}>
      <div className="grid">
        <div className="grid-child grid--push-start-3 grid-child--8-col-l grid-child--6-col-s">
          <div className="wrapper">
            <div className="privacy-policy is-active body" id="privacy-policy">
              <p>
                Fiftyone3 may collect personal information from visitors to this
                site. This information is used only to respond to enquiries and
                to monitor site usage. E-mail addresses received as part of an
                enquiry are only retained as long as the enquiry remains open.
              </p>
              <p>
                Where personal data is requested through forms (including
                registration forms), such data is only used for the purpose
                stated on the form and will not be given or sold to any third
                parties.
              </p>
              <p>
                This privacy policy only covers this Fiftyone3 website –
                websites linked to from this site are not covered by this
                policy.
              </p>
              <p>
                Fiftyone3 will at all times comply with the requirements of the
                Data Protection Act 1998.
              </p>
              <h3>Use of Cookies</h3>
              <p>
                Cookies are small text files that are placed on your computer by
                websites that you visit. They are widely used in order to make
                websites work, or work more efficiently, as well as to provide
                information to the owners of the site.
              </p>
              <p>The information below explains the cookies we use and why.</p>

              <p>
                By using our website, you agree that we can place these types of
                cookies on your device.
              </p>

              <table className="privacy-policy__table">
                <thead>
                  <tr>
                    <th>Cookie</th>
                    <th>Name</th>
                    <th>Purpose</th>
                    <th>More information</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Google Analytics</td>
                    <td>
                      _utma
                      <br />
                      _utmb
                      <br />
                      _utmc
                      <br />
                      _utmz
                    </td>
                    <td>
                      These cookies are used to collect information about how
                      visitors use our site. We use the information to compile
                      reports and to help us improve the site. The cookies
                      collect information in an anonymous form, including the
                      number of visitors to the site, where visitors have come
                      to the site from and the pages they visited.
                    </td>
                    <td>
                      <a
                        href="http://www.google.co.uk/intl/en/analytics/privacyoverview.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Click here for an overview of privacy at Google
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Sponsors />
  </Layout>
)

export default privacyPolicy
